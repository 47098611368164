import React from 'react';
import PropTypes from "prop-types";
import {MenuItem, TextField} from "@mui/material";
import appIcons from "./appIcons";

const AppIconSelect = ({label, value, onChange, ...props}) => {
    return <TextField
        select
        onChange={event => onChange(event.target.value)}
        value={value}
        label={label}
        {...props}
    >{appIcons.map(function (appIcon) {
        let AppIcon = appIcon.icon;
        return <MenuItem value={appIcon.name} key={appIcon.name}>
            {<AppIcon/>}
        </MenuItem>
    })}
      <MenuItem value={''} key={'no_icon_selected'}>
        {'NONE'}
      </MenuItem>
    </TextField>
};

AppIconSelect.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

export default AppIconSelect;
