import PropTypes from "prop-types";

const FieldDefinition = PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    data_source: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["string", "date", "float", "boolean"])
});

export default FieldDefinition;
