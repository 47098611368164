import React from 'react';
import PropTypes from "prop-types";
import appIcons from "./appIcons";

const AppIcon = ({iconData, defaultIcon = false, ...props}) => {
  const getStyle = () => {
    let style = {};

    if (iconData.size)
      style.fontSize = iconData.size;

    if (iconData.color)
      style.color = iconData.color;

    return style;
  }

  let AppIconElement;
  const AppIconObject = appIcons.find(appIcon => appIcon.name === iconData.name);
  if (AppIconObject)
    AppIconElement = AppIconObject.icon;
  else if (!defaultIcon)
    return null;
  else
    AppIconElement = defaultIcon;

  return <AppIconElement style={getStyle()} {...props}/>
};

AppIcon.propTypes = {
  iconData: PropTypes.any.isRequired,
  defaultIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.bool])
}

export default AppIcon;
