import * as React from 'react'
import Grid from '@mui/material/Grid'
import FilterCondition, { fieldTypeOperators } from './FilterCondition'
import { Button, Theme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { useEffect } from 'react'
import FilterConditionType, { FilterSet as FilterSetType } from './type/FilterCondition'
import Field from './type/Field'
import { DisabledCompareTypes } from './FilterBuilder'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
  orButton: {
    fontWeight: 600
  }
}))

type FilterSetProps = {
  value: FilterSetType,
  onChange: (newFilterSet: FilterSetType) => void,
  fields: Field[],
  onDeleted: () => void,
  allowEmpty?: boolean
  disabledCompareTypes?: DisabledCompareTypes
}

const FilterSet = ({ value, onChange, fields, onDeleted, allowEmpty = true, disabledCompareTypes }: FilterSetProps) => {
  const classes = useStyles()

  const updateFilterCondition = (index: number, newValue: FilterConditionType) => {
    const newConditions = [...value]
    newConditions[index] = newValue
    onChange(newConditions)
  }

  const addCondition = () => {
    onChange([...value, createNewCondition(fields[0], value)])
  }

  useEffect(() => {
    if (value.length === 0) {
      onChange([createNewCondition(fields[0])])
    }
  }, [value])

  const removeCondition = id => {
    if (value.length === 1) {
      onDeleted()
    } else {
      onChange(value.filter(cond => cond.id !== id))
    }
  }

  return <div>
    {value.map((filterConditionData, index) => (
      <Grid container spacing={1} key={index}>
        <Grid item xs={10}>
          <FilterCondition
            fields={fields}
            onChange={newValue => updateFilterCondition(index, newValue)}
            value={filterConditionData}
            disabledCompareTypes={disabledCompareTypes}
          />
        </Grid>
        <Grid item container xs={2} alignItems={'center'}>
          <Grid item xs={6}>
            <Button
              size={'large'}
              className={classes.orButton}
              onClick={addCondition}
            >AND</Button>
          </Grid>
          <Grid item xs={6}>
            {value.length > 1 || allowEmpty ?
              <IconButton
                onClick={() => removeCondition(filterConditionData.id)}
                aria-label='delete condition'
              >
                <DeleteIcon />
              </IconButton> : null
            }
          </Grid>
        </Grid>
      </Grid>
    ))}
  </div>
}

export const createNewCondition = (field: Field, existingValue: FilterSetType = []): FilterConditionType => {
  const defaultOperator = fieldTypeOperators[field.type][0]
  let defaultValue: string | boolean = ''
  if (field.type === 'date') {
    defaultValue = (Math.floor(new Date().getTime() / 1000) + '')
  } else if (field.type === 'boolean') {
    defaultValue = true
  }

  return {
    field: field.id,
    compare_type: defaultOperator.id,
    value: defaultValue,
    id: existingValue.length === 0 ? 0 : (Math.max.apply(Math, [0, ...existingValue.map(cond => cond.id)]) + 1)
  }
}

// @ts-ignore
// @deprecated
FilterSet.createNewCondition = createNewCondition;

export default FilterSet
