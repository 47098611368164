import * as PropTypes from 'prop-types'
import * as React from 'react'
import { FilterDataDefinition } from '../props/FilterDefinitions'
import FieldDefinition from '../props/FieldDefinition'
import FilterSet from './FilterSet'
import Box from '@mui/material/Box'
import { createStyles, makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Divider from '@mui/material/Divider'
import { Fragment } from 'react'
import { ConditionOperator, FilterData } from './type/FilterCondition'
import Field from './type/Field'

const useStyles = makeStyles(theme => createStyles({
  filterSetContainer: {
    border: '1px solid #b7b7b7',
    borderRadius: '4px'
  },
  orButton: {
    fontWeight: 600
  }
}))

enum FieldTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  FLOAT = 'float'
}

export type DisabledCompareTypes = {
  [fieldType in FieldTypes]?: ConditionOperator[]
}

export type FilterBuilderProps = {
  value: FilterData | null;
  onChange: (newValue: FilterData) => void;
  fields: Field[];
  allowEmpty?: boolean;
  disabledCompareTypes?: DisabledCompareTypes;
}

const FilterBuilder = ({
                         value,
                         onChange,
                         fields,
                         allowEmpty = true,
                         disabledCompareTypes = {}
                       }: FilterBuilderProps) => {
  const classes = useStyles()

  const updateFilterCondition = (index, newValue) => {
    const newFilterSets = [...value]
    newFilterSets[index] = newValue
    onChange(newFilterSets)
  }

  const addFilterSet = () => {
    onChange([...value, []])
  }

  const deleteFilterSet = index => {
    onChange(value.filter((value, setIndex) => setIndex !== index))
  }

  const initCondition = () => {
    onChange([[]])
  }

  return <div>
    {
      value === null || value.length === 0 ?
        <Button onClick={initCondition} variant={'contained'} disabled={fields.length === 0}>Add Condition</Button>
        : value.map((filterSetData, index) => (<Fragment key={index}>
          <Box p={1} className={classes.filterSetContainer}>
            <FilterSet
              fields={fields}
              onChange={newValue => updateFilterCondition(index, newValue)}
              value={filterSetData}
              onDeleted={() => deleteFilterSet(index)}
              allowEmpty={(allowEmpty || (!allowEmpty && value.length > 1))}
              disabledCompareTypes={disabledCompareTypes}
            />
          </Box>
          {
            index < value.length - 1 ?
              <Divider>OR</Divider>
              : null
          }
        </Fragment>))
    }
    {
      (value || []).length > 0 ?
        <Box mt={2}>
          <Button
            variant={'outlined'}
            endIcon={<AddIcon />}
            className={classes.orButton}
            size={'small'}
            onClick={addFilterSet}
          >OR</Button>
        </Box> : null
    }
  </div>
}

export default FilterBuilder
