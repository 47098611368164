import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {Typography } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import useMousePosition from './useMousePosition'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex !important'
  },
  headerRow: {
    display: 'flex !important',
    width: '100%'
  },
  column: {
    flex: '1',
    '& > .MuiTableCell-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '100%'
    }
  },
  draggable: {
    width: '10px',
    height: '100%',
    float: 'right',
    cursor: 'col-resize',
    '&:after': {
      content: '""',
      height: '100%',
      backgroundColor: '#d8d8d8',
      width: '2px',
      marginLeft: '4px',
      display: 'block'
    }
  }
}))

const ReportTableHeaderRow = ({ columns, onColumnsChanged, className = '', renderLabel, minPixelDiff = 0 }) => {
  const [draggingColumnId, setDraggingColumnId] = useState(null)
  const draggingColumn = draggingColumnId !== null ? columns.find(column => column.id === draggingColumnId) : null
  const [initialColumn1Flex, setInitialColumn1Flex] = useState(1)
  const [initialColumn2Flex, setInitialColumn2Flex] = useState(1)
  const [startXPos, setStartXPos] = useState(0)
  const containerRef = useRef()
  const { x } = useMousePosition()
  let secondDraggingColumn = null
  if (draggingColumnId !== null) {
    const index = columns.findIndex(column => column.id === draggingColumnId)
    secondDraggingColumn = columns[index + 1]
  }
  const classes = useStyles()

  useEffect(() => {
    document.addEventListener('mouseup', endDragging)
    return () => {
      document.removeEventListener('mouseup', endDragging)
      document.body.style.cursor = 'default' //Just in case!
    }
  }, [])

  useEffect(() => {
    if (draggingColumnId === null) {
      return
    }
    let totalFlex = 0
    const currentFlexWidth = draggingColumn.formatting_options.width ? draggingColumn.formatting_options.width : 1
    columns.forEach(column => totalFlex += column.formatting_options.width ? column.formatting_options.width : 1)
    const pixelsDiff = x - startXPos
    const containerWidth = containerRef.current.clientWidth
    const singleFlex = containerWidth / totalFlex
    const initialWidth = singleFlex * initialColumn1Flex
    const newWidth = initialWidth + pixelsDiff

    if (Math.abs((currentFlexWidth * singleFlex) - newWidth) <= minPixelDiff) {
      return
    }

    const newFlex = newWidth / containerWidth * totalFlex
    const newSecondColumnFlex = initialColumn2Flex + (initialColumn1Flex - newFlex)

    onColumnsChanged([
      {
        ...draggingColumn,
        formatting_options: {
          ...draggingColumn.formatting_options,
          width: newFlex
        }
      },
      {
        ...secondDraggingColumn,
        formatting_options: {
          ...secondDraggingColumn.formatting_options,
          width: newSecondColumnFlex
        }
      }
    ])
  }, [startXPos, draggingColumnId, x, containerRef, initialColumn1Flex])

  const endDragging = () => {
    setDraggingColumnId(null)
    document.body.style.cursor = 'default'
  }

  const startDragging = (column) => {
    setStartXPos(x)
    setDraggingColumnId(column.id)
    setInitialColumn1Flex(column.formatting_options.width ? column.formatting_options.width : 1)
    const nextColumn = columns[columns.findIndex(exColumn => exColumn.id === column.id) + 1]
    setInitialColumn2Flex(nextColumn.formatting_options.width ? nextColumn.formatting_options.width : 1)
    document.body.style.cursor = 'col-resize'
  }

  const defaultRender = column => (<Typography>{column.label}</Typography>)

  return <TableHead className={classes.root + ' ' + className} component={'div'}>
    <TableRow component={'div'} className={classes.headerRow} ref={containerRef}>
      {columns.map((column, index) => (
        <div
          className={classes.column}
          style={{ flex: column.formatting_options.width ? column.formatting_options.width : 1 }}
          key={column.id}
        >
          <TableCell
            component={'div'}
          >
            <div>
              {!renderLabel ? defaultRender(column) : renderLabel(column)}
            </div>
            {index !== columns.length - 1 ?
              <div className={classes.draggable} onMouseDown={(event) => {
                startDragging(column);
                event.preventDefault();
              }} /> : null}
          </TableCell>
        </div>
      ))}
    </TableRow>
  </TableHead>
}

ReportTableHeaderRow.propTypes = {
  columns: PropTypes.array.isRequired,
  onColumnsChanged: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderLabel: PropTypes.func,
  minPixelDiff: PropTypes.number
}

export default ReportTableHeaderRow
