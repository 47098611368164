import * as React from 'react'
import * as PropTypes from 'prop-types'
import { FilterConditionDefinition } from '../props/FilterDefinitions'
import FieldDefinition from '../props/FieldDefinition'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FilterValueTextField from './FilterValueTextField'
import { Fragment } from 'react'
import FilterCondition, { ConditionOperator } from './type/FilterCondition'
import Field from './type/Field'
import { DisabledCompareTypes } from './FilterBuilder'


const fieldTypeOperators: {
  [type: string]: {
    id: ConditionOperator,
    label: string
  }[]
} = {
  float: [
    {
      id: 'less_than_equal_to',
      label: 'Less than or equal to'
    },
    {
      id: 'greater_than_equal_to',
      label: 'Greater than or equal to'
    },
    {
      id: 'less_than',
      label: 'Less than'
    },
    {
      id: 'more_than',
      label: 'More than'
    },
    {
      id: 'equal_to',
      label: 'Is'
    },
    {
      id: 'not_equal_to',
      label: 'Is not'
    },
    {
      id: 'is_null',
      label: 'Is Null'
    },
    {
      id: 'is_not_null',
      label: 'Is Not Null'
    }
  ],
  string: [
    {
      id: 'contains',
      label: 'Contains'
    },
    {
      id: 'not_contains',
      label: 'Does not contain'
    },
    {
      id: 'equal_to',
      label: 'Is'
    },
    {
      id: 'not_equal_to',
      label: 'Is not'
    },
    {
      id: 'is_null',
      label: 'Is Null'
    },
    {
      id: 'is_not_null',
      label: 'Is Not Null'
    }
  ],
  date: [
    {
      id: 'greater_than',
      label: 'Is newer than'
    },
    {
      id: 'less_than',
      label: 'Is older than'
    },
    {
      id: 'greater_than_relative',
      label: 'Is newer than (relative)'
    },
    {
      id: 'less_than_relative',
      label: 'Is older than (relative)'
    },
    {
      id: 'is_null',
      label: 'Is Null'
    },
    {
      id: 'is_not_null',
      label: 'Is Not Null'
    },
    {
      id: 'is_month',
      label: 'Is Month'
    }
  ],
  boolean: [
    {
      id: 'equal_to',
      label: 'Is'
    },
    {
      id: 'is_null',
      label: 'Is Null'
    },
    {
      id: 'is_not_null',
      label: 'Is Not Null'
    }
  ]
}

export { fieldTypeOperators }

type FilterConditionProps = {
  value: FilterCondition,
  onChange: (filterCondition: FilterCondition) => void,
  fields: Field[],
  disabledCompareTypes?: DisabledCompareTypes
}

const FilterCondition = ({ value, onChange, fields, disabledCompareTypes = {} }: FilterConditionProps) => {
  let selectedField = fields.find(field => field.id === value.field)
  const availableOperators = fieldTypeOperators[selectedField.type].filter(
    ({ id }) => (disabledCompareTypes[selectedField.type]|| []).indexOf(id) === -1
  )

  const updateField = (field: keyof FilterCondition, fieldValue: string | number | boolean) => {
    const newValue: FilterCondition = { ...value }
    if (field === 'field') {
      selectedField = fields.find(field => field.id === fieldValue)
      const newAvailableOperators = fieldTypeOperators[selectedField.type].filter(
        ({ id }) => (disabledCompareTypes[selectedField.type]|| []).indexOf(id) === -1
      );
      newValue['compare_type'] = newAvailableOperators[0].id
    }

    if (field !== 'value') {
      newValue['value'] = selectedField.type === 'date' ? (Math.floor(new Date().getTime() / 1000) + '') : ''
    }
    if (selectedField.type === 'boolean' && field === 'field') {
      newValue.value = true
    }

    // @ts-ignore
    newValue[field] = fieldValue

    onChange(newValue)
  }


  return <Grid container spacing={2}>
    <Grid item xs={4}>
      <TextField
        label={'Field'}
        select
        fullWidth
        size={'small'}
        value={value.field}
        onChange={event => updateField('field', event.target.value)}
        disabled={fields.length < 2}
      >
        {fields.map(field => (
          <MenuItem value={field.id} key={field.id}>{field.label}</MenuItem>
        ))}
      </TextField>
    </Grid>
    {selectedField.type !== 'boolean' ? <Fragment>
      <Grid item xs={4}>
        <TextField
          label={'Operator'}
          select
          size={'small'}
          fullWidth
          value={value.compare_type}
          onChange={event => updateField('compare_type', event.target.value)}
        >
          {availableOperators.map(operator => (
            <MenuItem value={operator.id} key={operator.id}>{operator.label}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <FilterValueTextField
          compare_type={value.compare_type}
          field={selectedField}
          value={value.value}
          onChange={value => updateField('value', value)}
          size={'small'}
        />
      </Grid>
    </Fragment> : <Grid item xs={8}>
      <TextField
        label={'Operator'}
        select
        size={'small'}
        fullWidth
        value={value.value ? 'true' : 'false'}
        onChange={event => updateField('value', event.target.value !== 'false')}
      >
        <MenuItem value={'true'}>Is true</MenuItem>
        <MenuItem value={'false'}>Is false</MenuItem>
      </TextField>
    </Grid>
    }
  </Grid>
}

export default FilterCondition
