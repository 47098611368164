import {
  CalendarToday,
  Description,
  FindInPage,
  Home,
  InfoOutlined,
  InsertChart,
  LocalOffer,
  MenuBook,
  RemoveCircleOutlined,
  Search,
  Warning,
  Image,
  Check,
  CheckBox,
  TrendingUp,
  Label,
  LabelImportant,
  FiberManualRecord,
  FiberManualRecordOutlined,
  FilterList,
  WarningOutlined
} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";

const appIcons = [
    {name: 'CalendarToday', icon: CalendarToday},
    {name: 'Check', icon: Check},
    {name: 'CheckBox', icon: CheckBox},
    {name: 'Description', icon: Description},
    {name: 'FindInPage', icon: FindInPage},
    {name: 'FiberManualRecord', icon: FiberManualRecord},
    {name: 'FiberManualRecordOutlined', icon: FiberManualRecordOutlined},
    {name: 'FilterList', icon: FilterList},
    {name: 'Home', icon: Home},
    {name: 'Image', icon: Image},
    {name: 'InfoOutlined', icon: InfoOutlined},
    {name: 'InsertChat', icon: InsertChart},
    {name: 'Label', icon: Label},
    {name: 'LabelImportant', icon: LabelImportant},
    {name: 'LocalOffer', icon: LocalOffer},
    {name: 'MenuBook', icon: MenuBook},
    {name: 'RemoveCircleOutlined', icon: RemoveCircleOutlined},
    {name: 'Search', icon: Search},
    {name: 'StarIcon', icon: StarIcon},
    {name: 'TrendingUp', icon: TrendingUp},
    {name: 'Warning', icon: Warning},
    {name: 'WarningOutlined', icon: WarningOutlined}
]

export default appIcons;
