import * as React from 'react'
import * as PropTypes from 'prop-types'
import FieldDefinition from '../props/FieldDefinition'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { getUnixTime, fromUnixTime, getDate } from 'date-fns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import Field from './type/Field'
import { ConditionOperator, FilterConditionValue } from './type/FilterCondition'
import { MenuItem } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

const FilterValueDate = ({ value, onChange, ...props }: FilterValueTextProps) => {
  const onDateChange = date => {
    onChange(getUnixTime(date))
  }

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      value={value === '' ? new Date() : fromUnixTime(value as number)}
      onChange={onDateChange}
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  </LocalizationProvider>
}

type FilterValueTextProps = {
  value: FilterConditionValue,
  onChange: (newValue: FilterConditionValue) => void
} & TextFieldProps

const FilterValueText = (
  {
    value,
    onChange,
    ...props
  }: FilterValueTextProps) => (
  <TextField
    value={value}
    onChange={event => onChange(event.target.value)}
    {...props}
  />
)

const FilterValueNumeric = (props: FilterValueTextProps) => (
  <FilterValueText
    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    type={'number'}
    {...props}
  />
)

const FilterValueMonthSelect = ({ value, onChange, ...props }: FilterValueTextProps) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  if (!months.includes(value as string)) {
    onChange(months[0])
  }
  return <TextField
    select
    onChange={event => onChange(event.target.value)}
    value={value || months[0]}
    {...props}
  >
    {months.map(month => (
      <MenuItem key={month} value={month}>{month}</MenuItem>
    ))}
  </TextField>
}

export type FilterValueTextFieldProps = {
  field: Field;
  compare_type: ConditionOperator;
  value: FilterConditionValue;
  onChange: (newValue: FilterConditionValue) => void;
} & TextFieldProps;

const FilterValueTextField = ({ field, compare_type, value, onChange, ...props }: FilterValueTextFieldProps) => {

  const compMap = {
    float: FilterValueNumeric,
    string: FilterValueText,
    date: FilterValueDate
  }

  const getValueField = (field, compare_type) => {
    if (compare_type === 'is_month') {
      return FilterValueMonthSelect
    }
    if (compare_type === 'greater_than_relative' || compare_type === 'less_than_relative') {
      return FilterValueText
    }

    return compMap[field.type]
  }

  const ValueField = getValueField(field, compare_type)

  const onValueChange = (val) => {
    onChange(val)
  }

  return <ValueField
    label={'Value'}
    value={value}
    onChange={onValueChange}
    fullWidth
    {...props}
  />
}

FilterValueTextField.propTypes = {
  field: FieldDefinition.isRequired,
  compare_type: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default FilterValueTextField
